import React, {ReactNode} from "react"
import {css} from "@linaria/core"

import {ContentRight} from "../content/content-right"

type Props = Parameters<typeof ContentRight>[0]

export const InnerRight = (props: Props) => (
  <div className={styles}>
    <ContentRight {...props} />
  </div>
)

const styles = css`
  display: flex;
  width: 100%;
  height: 100%;
  background: url(/images/paper.jpg);
  background-size: cover;
  background-position: 100% 50%;

  @media (max-height: 42rem) and (min-width: 70rem) {
    height: max-content;
    min-height: 100%;
  }
`
