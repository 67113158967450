import React from "react"
import {css} from "@linaria/core"

import {mainWidth} from "./settings"
import {ContentLeft} from "../content/content-left"

export const InnerLeft = () => (
  <div className={styles}>
    <ContentLeft>
      <h2>
        <span className="outline">Westfield</span> <span>Mall of the Netherlands</span>
      </h2>
    </ContentLeft>
  </div>
)

const styles = css`
  display: flex;
  width: 100%;
  height: 100%;
  background: url(/images/paper.jpg);
  background-size: cover;
  background-position: 100% 50%;

  @media (max-width: 70rem) {
    width: 100%;
    height: max-content;
  }

  h2 {
    width: ${mainWidth / 2}rem;
    margin: 0 0 0 3rem;
    font-size: 3rem;
    line-height: 3.2rem;

    @media (max-width: 70rem) {
      margin-left: 2.5rem;
      line-height: 2.7rem;
    }

    @media (max-height: 50rem) {
      font-size: 2.5rem;
      line-height: 2.7rem;
    }

    span {
      display: block;
    }

    span.outline {
      color: transparent;
      -webkit-text-stroke: 0.07rem white;
      text-stroke: 0.07rem white;
    }
  }
`
