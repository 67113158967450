import React from "react"
import {css} from "@linaria/core"

import {Invitation} from "./data"
import {textColorHex, redColorHex} from "../layout/settings"
import {Footer} from "./footer"

type Props = Pick<Invitation, "program" | "footer">

export const ContentRight = ({footer, program: [{date, item, about}]}: Props) => {
  const weekday = new Date(date).toLocaleDateString("nl", {weekday: "long"})

  const fullDate = new Date(date).toLocaleDateString("nl", {
    day: "numeric",
    month: "long",
    year: "numeric",
  })

  return (
    <div className={styles}>
      <aside>
        <h2>{about}</h2>
        <h1>
          <span className="outline">{weekday}</span>
          <span>{fullDate}</span>
        </h1>
        <ul>
          {item.map(({time, description}, idx) => (
            <li key={idx}>
              <span className="time">{time}</span>
              <span className="description">{description}</span>
            </li>
          ))}
        </ul>
      </aside>

      <Footer footers={footer.slice(2, 3)} logo={true} />
    </div>
  )
}

const styles = css`
  width: 100%;
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${textColorHex};

  @media (max-height: 42rem) and (min-width: 70rem) {
    height: auto;
  }

  aside {
    height: 80%;
    padding: 5rem 4rem 2rem 4rem;
    box-sizing: border-box;
    flex-grow: 1;

    @media (max-width: 70rem) {
      padding: 5rem 2.5rem 2rem 2.5rem;
    }

    @media (max-height: 50rem) and (min-width: 70rem) {
      padding: 4rem 4rem 2rem;
    }

    @media (max-height: 42rem) and (min-width: 70rem) {
      height: 100%;
    }

    h2 {
      margin: 0;
      text-align: left;
      width: 100%;
      color: ${redColorHex};
      font-size: 2rem;
    }

    h1 {
      width: 100%;
      margin: 1.8rem 0 0 0;
      color: ${textColorHex};
      font-size: 3rem;
      line-height: 3.6rem;

      span {
        display: block;
      }

      span.outline {
        color: transparent;
        -webkit-text-stroke: 0.07rem ${textColorHex};
        text-stroke: 0.07rem ${textColorHex};
      }

      @media (max-height: 50rem) and (min-width: 70rem) {
        margin-top: 1rem;
        font-size: 2.5rem;
        line-height: 2.7rem;
      }

      @media (max-height: 44rem) and (min-width: 70rem) {
        font-size: 1.6rem;
        line-height: 1.8rem;

        span.outline {
          color: ${textColorHex};
          -webkit-text-stroke-width: 0;
          text-stroke-width: 0;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      margin: 1.2rem 0 0;
      padding: 0;
      font-size: 1.4rem;
      line-height: 2rem;
      flex-grow: 1;
      justify-content: space-between;

      li {
        margin: 0;
        padding: 0;
        list-style: none;

        span.time {
          display: block;
          padding: 0.4rem 0;
          font-weight: bold;
          border-bottom: 0.1rem solid ${textColorHex};

          @media (max-height: 53rem) and (min-width: 70rem) {
            padding: 0.3rem 0;
          }
        }

        span.description {
          display: block;
          padding: 1rem 0;
        }
      }
    }

    button {
      margin: 0 auto;
      min-width: 60%;
    }
  }
`
