import React, {ReactNode} from "react"
import {css} from "@linaria/core"

import {mainWidth, redColorHex} from "../layout/settings"

interface Props {
  children?: ReactNode
}

export const ContentLeft = ({children}: Props) => {
  return (
    <div className={styles}>
      <div className="photo" />
      <div className="red">{children}</div>
    </div>
  )
}

const styles = css`
  min-width: ${mainWidth * 0.65}rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 70rem) {
    width: 100%;
    height: max-content;
  }

  > div.photo {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    max-width: 95vw;

    background-color: black;
    background: url(/images/andre.jpg);
    background-size: ${mainWidth * 0.9}rem;
    background-position: -7rem 30%;
    background-repeat: no-repeat;

    @media (max-width: 70rem) {
      height: 30rem;
      background-size: cover;
      background-position: 50% 30%;
    }

    @media (max-height: 50rem) and (min-width: 70rem) {
      background-size: ${mainWidth * 0.92}rem;
      background-position: -8rem 30%;
      height: 85%;
    }
  }

  > div.red {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    min-height: 13rem;
    background: ${redColorHex};
    mix-blend-mode: multiply;
    max-width: 95vw;

    @media (max-height: 50rem) and (min-width: 70rem) {
      min-height: 12rem;
      height: 15%;
    }
  }
`
