import React, {useCallback, useReducer, useState} from "react"
import {css} from "@linaria/core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faPartyHorn} from "@fortawesome/pro-solid-svg-icons"
import {useErrorHandler} from "react-error-boundary"

import {Modal} from "./modal"
import {Checkbox} from "../form/checkbox"

import {
  InvitationDetails,
  InvitationPreferences,
  updateInvitation,
  sendConfirmationEmail,
} from "./data"

import {textColorHex} from "../layout/settings"

interface Props {
  invitationDetails: InvitationDetails
  signUp: string
  about: string
  request: string
  submit: string
  thankYou: string
}

export const ResponseButton = ({
  invitationDetails,
  signUp,
  about,
  request,
  submit,
  thankYou,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleError = useErrorHandler()

  const [
    {
      id,
      name,
      email,
      responded,
      bringsGuest,
      alternativeGuest,
      guestName,
      guestEmail,
      dietaryWishes,
    },
    setDetails,
  ] = useReducer(reduceDetails, {
    dietaryWishes: "",
    bringsGuest: false,
    alternativeGuest: false,
    guestName: "",
    guestEmail: "",
    ...invitationDetails,
  })

  const handleButtonClick = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  const handleCancel = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const handleSubmit = useCallback(async () => {
    try {
      await updateInvitation({
        bringsGuest,
        alternativeGuest,
        guestName,
        guestEmail,
        dietaryWishes,
        email,
      })

      await sendConfirmationEmail({
        email,
        name,
        guestEmail,
        guestName,
        bringsGuest,
        alternativeGuest,
        id,
      })

      setDetails({kind: ActionKind.SetHasResponded})
    } catch (error) {
      handleError(error)
    }
  }, [
    id,
    bringsGuest,
    alternativeGuest,
    guestName,
    guestEmail,
    dietaryWishes,
    setModalOpen,
  ])

  return (
    <>
      <button onClick={handleButtonClick}>
        {responded && <FontAwesomeIcon icon={faCheck} />}
        {signUp}
      </button>

      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        submitButton={submit}
        className={styles}
        thankYou={thankYou}
        title={
          <>
            <FontAwesomeIcon icon={faPartyHorn} />
            {about}
          </>
        }
      >
        <p>{request}</p>

        <label className="vertical fixed">
          <span>Naam</span>
          <span className="value">{name}</span>
        </label>

        <label className="vertical fixed">
          <span>E-mail</span>
          <span className="value">{email}</span>
        </label>

        <label className="vertical">
          <span>Dieetwensen</span>
          <textarea
            rows={3}
            placeholder="Ik heb de volgende dieetwensen"
            value={dietaryWishes}
            onChange={event =>
              setDetails({kind: ActionKind.SetDietaryWishes, value: event.target.value})
            }
          />
        </label>

        <label
          onClick={() => {
            if (alternativeGuest == true) {
              setDetails({kind: ActionKind.ToggleAlternativeGuest})
            }
            setDetails({kind: ActionKind.ToggleBringsGuest})
          }}
        >
          <Checkbox checked={bringsGuest} />
          Ik neem een collega mee
        </label>

        <label
          onClick={() => {
            if (bringsGuest == true) {
              setDetails({kind: ActionKind.ToggleBringsGuest})
            }
            setDetails({kind: ActionKind.ToggleAlternativeGuest})
          }}
        >
          <Checkbox checked={alternativeGuest} />
          Iemand anders gaat in mijn plaats
        </label>

        <div className={bringsGuest || alternativeGuest ? "visible" : "hidden"}>
          <label className="vertical">
            <span>Naam collega</span>
            <input
              type="text"
              value={guestName}
              onChange={event =>
                setDetails({kind: ActionKind.SetGuestName, value: event.target.value})
              }
            />
            <span id="email-span">E-mail collega</span>
            <input
              type="text"
              value={guestEmail}
              onChange={event =>
                setDetails({kind: ActionKind.SetGuestEmail, value: event.target.value})
              }
            />
          </label>
        </div>
      </Modal>
    </>
  )
}

enum ActionKind {
  SetDietaryWishes,
  ToggleBringsGuest,
  ToggleAlternativeGuest,
  SetGuestName,
  SetGuestEmail,
  SetHasResponded,
}

interface SetDietaryWishesAction {
  kind: ActionKind.SetDietaryWishes
  value: string
}

interface ToggleBringsGuestAction {
  kind: ActionKind.ToggleBringsGuest
}

interface SetGuestNameAction {
  kind: ActionKind.SetGuestName
  value: string
}

interface SetGuestEmailAction {
  kind: ActionKind.SetGuestEmail
  value: string
}

interface SetHasResponded {
  kind: ActionKind.SetHasResponded
}

interface ToggleAlternativeGuestAction {
  kind: ActionKind.ToggleAlternativeGuest
}

type Action =
  | SetDietaryWishesAction
  | ToggleBringsGuestAction
  | SetGuestNameAction
  | SetGuestEmailAction
  | SetHasResponded
  | ToggleAlternativeGuestAction

interface Details extends InvitationDetails, InvitationPreferences {}

const reduceDetails = (state: Details, action: Action) => {
  switch (action.kind) {
    case ActionKind.SetDietaryWishes:
      return {...state, dietaryWishes: action.value}
    case ActionKind.ToggleBringsGuest:
      return {...state, bringsGuest: !state.bringsGuest}
    case ActionKind.ToggleAlternativeGuest:
      return {...state, alternativeGuest: !state.alternativeGuest}
    case ActionKind.SetGuestName:
      return {...state, guestName: action.value}
    case ActionKind.SetGuestEmail:
      return {...state, guestEmail: action.value}
    case ActionKind.SetHasResponded:
      return {...state, responded: true}
  }
}

const styles = css`
  label {
    margin: 1.5rem 0;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    line-height: 2rem;
    font-size: 1.6rem;

    &.vertical {
      flex-direction: column;
    }

    &.fixed {
      cursor: default;
    }

    > textarea {
      resize: none;
    }

    > textarea,
    > input[type="text"] {
      display: block;
      margin: 1rem 0 0;
      box-sizing: border-box;
      padding: 1rem;
      width: 100%;
      outline: none;
      background: rgb(0, 0, 0, 0.05);
      border: 0.2rem solid transparent;
      border-radius: 0.3rem;
      will-change: border-color;
      transition: border-color 0.2s ease-in-out;
      font-family: "Proxima Nova", "Helvetica", sans-serif;
      font-weight: normal;
      font-size: 1.6rem;
      color: ${textColorHex};

      &:focus {
        border-color: rgb(0, 0, 0, 0.06);
      }

      &::placeholder {
        color: rgb(0, 0, 0, 0.3);
      }
    }

    > span {
      display: block;
    }

    > span.value {
      display: block;
      margin: 1rem 0 0;
      padding: 0;
      width: 100%;
      font-weight: normal;
    }
  }

  div.hidden {
    overflow: hidden;
    transform: translateZ(0); // Fixes input text being invisible in Chrome 106/107
    height: 0;
    will-change: height;
    transition: height 0.2s ease-in-out;
  }

  #email-span {
    margin-top: 2rem;
  }

  div.visible {
    overflow: hidden;
    transform: translateZ(0); // Fixes input text being invisible in Chrome 106/107
    height: 18rem; // Only works for input[type=text]
    will-change: height;
    transition: height 0.2s ease-in-out;
  }
`
