import React, {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"

import {createPortal} from "react-dom"
import {css} from "@linaria/core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faArrowRight, faCheck} from "@fortawesome/pro-solid-svg-icons"

import {
  appearEasing,
  appearSpeed,
  disappearSpeed,
  redColorHex,
  shadowColorRGB,
  submitSpeed,
  textColorHex,
} from "../layout/settings"

interface Props {
  open: boolean
  title: ReactNode
  thankYou?: ReactNode
  transparent?: boolean
  submitButton?: ReactNode
  className?: string
  onCancel?: () => void
  onSubmit?: () => void
  children: ReactNode
}

type Transition = "opening" | "submitting" | "closing"

export const Modal = ({
  open,
  title,
  thankYou,
  transparent,
  submitButton,
  className,
  onCancel,
  onSubmit,
  children,
}: Props) => {
  const [submitted, setSubmitted] = useState(false)
  const [transition, setTransition] = useState<Transition | undefined>()
  const ref = useRef<HTMLElement>(null)

  const handleSubmit = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation()
      setTransition("submitting")
      setSubmitted(true)
      onSubmit?.()
    },
    [setTransition, setSubmitted, onSubmit],
  )

  const handleClose = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      setTransition("closing")
      onCancel?.()
    },
    [setTransition, onCancel],
  )

  const handleBackgroundClick = useCallback(
    (event: MouseEvent) => {
      if (ref.current?.contains(event.target as HTMLElement)) return

      event.stopPropagation()
      setTransition("closing")
      onCancel?.()
    },
    [setTransition, onCancel],
  )

  const handleEscapePress = useCallback(
    (event: KeyboardEvent) => {
      if (open && event.key === "Escape") {
        setTransition("closing")
        onCancel?.()
      }
    },
    [setTransition, open, onCancel],
  )

  useEffect(() => {
    document.body.addEventListener("keydown", handleEscapePress)
    return () => document.body.removeEventListener("keydown", handleEscapePress)
  }, [handleEscapePress])

  useLayoutEffect(() => {
    if (open) {
      setSubmitted(false)
      setTransition("opening")
    }
  }, [setTransition, open])

  const handleAnimationEnd = useCallback(() => {
    setTransition(undefined)
  }, [setTransition])

  if (!open && !transition) return null

  return (
    <Portal wrapperId="modal">
      <section
        className={[
          styles,
          transition,
          className,
          transparent ? "transparent" : undefined,
          submitted ? "submitted" : undefined,
          submitButton ? undefined : "nosubmit",
        ]
          .filter(Boolean)
          .join(" ")}
        onClick={handleBackgroundClick}
        onAnimationEnd={handleAnimationEnd}
      >
        <div className="transform">
          <section className="modal" ref={ref}>
            <section className="thankyou">
              <div className="spinner" />
              <p>
                <FontAwesomeIcon icon={faCheck} />
                {thankYou}
              </p>
              <button onClick={handleClose}>Sluit deze pop-up</button>
            </section>
            <section className="content">
              <h2>{title}</h2>
              {children}
            </section>
            {submitButton && (
              <button onClick={handleSubmit}>
                {submitButton}
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            )}
          </section>
        </div>
      </section>
    </Portal>
  )
}

const Portal = ({
  children,
  wrapperId,
}: {
  children: React.ReactNode
  wrapperId: string
}) => {
  let element = document.getElementById(wrapperId)
  if (!element) {
    element = createWrapperAndAppendToBody(wrapperId)
  }

  return createPortal(children, element)
}

const createWrapperAndAppendToBody = (wrapperId: string) => {
  const wrapperElement = document.createElement("div")
  wrapperElement.setAttribute("id", wrapperId)
  document.body.appendChild(wrapperElement)
  return wrapperElement
}

const styles = css`
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: rgba(30, 30, 32, 0.6);
  will-change: opacity;

  align-items: center;
  justify-content: center;

  perspective: 150rem;
  perspective-origin: center;
  color: ${textColorHex};

  &.transparent {
    background: transparent;
  }

  > div.transform {
    will-change: transform;
    max-height: 100vh;
    max-height: 100svh;

    > section.modal {
      display: grid;
      grid-template-rows: 1fr 8rem;

      width: 50rem;
      background: white;
      background-image: url(/images/paper.jpg);
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 0.2rem;
      box-sizing: border-box;
      box-shadow: 0 2rem 4rem 0 rgba(${shadowColorRGB}, 0.4);
      will-change: transform, opacity;
      align-items: center;

      transform-origin: 80% 80%;

      max-height: 100vh;
      max-height: 100svh;

      max-width: 100vw;
      overflow-y: auto;

      > section.thankyou {
        display: flex;
        grid-row: 1 / 3;
        pointer-events: none;
        overflow: hidden;
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        flex-direction: column;
        margin: 0;
        padding: 0;
        color: white;
        align-items: center;
        justify-content: flex-start;

        > p {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          justify-self: center;
          margin: 0 5rem;
          padding: 2rem 5rem;
          text-align: center;
          box-sizing: border-box;
          font-weight: normal;
          font-size: 2rem;
          line-height: 2.6rem;
          opacity: 0;
          will-change: opacity;
          max-height: calc(100vh - 8rem);

          @media (max-width: 50rem) {
            margin: 0 2rem;
            padding: 2rem 2rem;
          }

          > svg {
            width: 4rem;
            height: 4rem;
            padding: 3rem;
            margin-bottom: 3rem;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 50%;
          }
        }

        > button {
          text-transform: uppercase;
          font-size: 1.8rem;
          font-weight: bold;
          margin-bottom: 1.5rem;
          color: white;
          border: none;
          opacity: 0;
          will-change: opacity;

          &:hover {
            background: transparent;
          }
        }

        > div.spinner {
          visibility: hidden;
          position: absolute;
          bottom: 6rem;
          display: flex;
          height: 8rem;
          width: 100%;
          box-sizing: border-box;
          border-radius: 0;
          background: ${redColorHex};
          will-change: border-radius, width, height, transform;
        }
      }

      > section.content {
        display: flex;
        flex-direction: column;
        padding: 3rem 5rem;

        @media (max-width: 50rem) {
          padding: 3rem;
        }

        > h2 {
          margin: 1rem 0 2rem;
          text-align: center;
          width: 100%;
          color: ${redColorHex};
          font-size: 2rem;

          > svg {
            margin-right: 1.5rem;
          }
        }

        > p {
          text-align: center;
        }
      }

      > button {
        display: flex;
        height: 8rem;
        width: 100%;
        box-sizing: border-box;
        border-radius: 0;
        border-width: 0;
        background: ${redColorHex};
        color: white;
        text-align: left;
        padding: 3rem 5rem;
        justify-content: space-between;

        svg {
          will-change: transform;
          transition: transform 0.1s ease-in-out;
        }

        &:hover {
          svg {
            transform: translateX(0.5rem);
          }
        }
      }
    }
  }

  &.nosubmit {
    > div.transform {
      > section.modal {
        grid-template-rows: 1fr;
      }
    }
  }

  &.submitted {
    > div.transform {
      > section.modal {
        > section.thankyou {
          pointer-events: auto;
          > p,
          > button {
            animation: 0.2s ease-in-out ${submitSpeed} forwards fade-appear;
          }

          > div.spinner {
            visibility: visible;
            animation: ${submitSpeed} ease-in-out both circle-grow;
          }
        }

        > button {
          visibility: hidden;
        }
      }
    }
  }

  &.opening {
    animation: ${appearSpeed} ease-in-out both fade-appear;

    > div.transform {
      > section.modal {
        animation: ${appearSpeed} ${appearEasing} both scale-appear;
      }
    }
  }

  &.submitting {
    > div.transform {
      > section.modal {
        > section.thankyou {
          > div.spinner {
            visibility: visible;
            animation: ${submitSpeed} ease-in-out both circle-grow;
          }
        }

        > button {
          visibility: hidden;
        }
      }
    }
  }

  &.closing {
    animation: ${disappearSpeed} ease-in-out both fade-appear reverse;
  }

  @keyframes scale-appear {
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes fade-appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes circle-grow {
    0% {
      border-radius: 4rem;
      width: 60rem;
      height: 8rem;
    }
    10% {
      transform: translateY(0);
    }
    30% {
      border-radius: 6rem;
      width: 12rem;
      height: 12rem;
      transform: translateY(2rem);
    }
    30.1% {
      border-radius: 50%;
    }
    100% {
      border-radius: 50%;
      width: 100rem;
      height: 100rem;
      transform: translateY(15rem);
    }
  }
`
