import React from "react"
import {css} from "@linaria/core"

import {Footer as FooterProps} from "./data"
import {footerHeight, textColorHex} from "../layout/settings"

interface Props {
  footers: FooterProps[]
  logo: boolean
}

export const Footer = ({footers, logo}: Props) => (
  <nav className={styles}>
    {footers.map(({title, contentNode}, idx) => (
      <div key={idx}>
        <h3>{title}</h3>
        <div
          dangerouslySetInnerHTML={{
            /* :'( */
            __html: contentNode.childMarkdownRemark.html.replace(
              "href=",
              'target="_blank" rel="noreferrer" href=',
            ),
          }}
        />
        {logo && <span className="bouwens" />}
      </div>
    ))}
  </nav>
)

const styles = css`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  padding: 3.5rem 4rem 2rem;
  flex-direction: row;
  box-sizing: border-box;
  height: 20%;
  min-height: ${footerHeight}rem;
  background: #242424;
  mix-blend-mode: multiply;
  color: white;
  font-size: 1.4rem;
  align-items: flex-start;

  @media (max-width: 70rem) {
    padding: 3rem 2.5rem;
    flex-direction: column;
    height: auto;
  }

  @media (max-height: 50rem) and (min-width: 70rem) {
    padding-top: 2rem;
    align-items: start;
    min-height: 0;
    height: 15%;
    font-size: 1.2rem;
  }

  @media (max-height: 42rem) and (min-width: 70rem) {
    background: transparent;
    color: ${textColorHex};
    height: auto;
    min-height: min-content;
    padding-top: 0;
  }

  a {
    color: white;
    text-decoration: underline;

    @media (max-height: 42rem) and (min-width: 70rem) {
      color: ${textColorHex};
    }
  }

  > div {
    @media (max-width: 70rem) {
      width: 100%;
    }

    @media (max-height: 50rem) and (min-width: 70rem) {
      min-width: 35%;
    }

    &:first-child {
      margin-right: 4rem;

      @media (max-width: 70rem) {
        margin-right: 0;
      }
    }

    &:last-child {
      @media (max-width: 70rem) {
        margin-top: 2rem;
      }
    }
  }

  h3 {
    margin: 0;
    font-size: 1.5rem;

    @media (max-height: 50rem) and (min-width: 70rem) {
      font-size: 1.3rem;
    }

    @media (max-height: 42rem) and (min-width: 70rem) {
      color: ${textColorHex};
    }
  }

  p {
    margin: 0.6rem 0 0;

    @media (max-height: 50rem) and (min-width: 70rem) {
      line-height: 1.8rem;
    }
  }
}
span.bouwens {
  display: block;
  margin: 0.8rem 0;
  width: 100%;
  height: 1.2rem;
  background-image: url(/images/bouwens-colored.svg);
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: 25rem) {
    height: 1.3rem;
  }
}
`
