import React, {useCallback, useReducer, useState} from "react"
import {css} from "@linaria/core"

import {Modal} from "./modal"
import {textColorHex} from "../layout/settings"

export const ContactButton = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleButtonClick = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  const handleCancel = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  return (
    <>
      <button className={styles} onClick={handleButtonClick}>
        Ja, ik ben er graag bij!
      </button>

      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        className={styles}
        title={"Uitnodiging niet gevonden"}
      >
        <p>
          Neem alstublieft contact op met Katinka Lemmen via
          <br />
          <a href="mailto:katinka@bouwensand.com">katinka@bouwensand.com</a> of{" "}
          <a href="tel:+31628825511">06-28825511</a>.
        </p>
      </Modal>
    </>
  )
}

const styles = css`
  label {
    margin: 1.5rem 0;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    line-height: 2rem;
    font-size: 1.6rem;

    &.vertical {
      flex-direction: column;
    }

    &.fixed {
      cursor: default;
    }

    > textarea {
      resize: none;
    }

    > textarea,
    > input[type="text"] {
      display: block;
      margin: 1rem 0 0;
      box-sizing: border-box;
      padding: 1rem;
      width: 100%;
      outline: none;
      background: rgb(0, 0, 0, 0.05);
      border: 0.2rem solid transparent;
      border-radius: 0.3rem;
      will-change: border-color;
      transition: border-color 0.2s ease-in-out;
      font-family: "Proxima Nova", "Helvetica", sans-serif;
      font-weight: normal;
      font-size: 1.6rem;
      color: ${textColorHex};

      &:focus {
        border-color: rgb(0, 0, 0, 0.06);
      }

      &::placeholder {
        color: rgb(0, 0, 0, 0.3);
      }
    }
  }

  div.hidden {
    overflow: hidden;
    transform: translateZ(0); // Fixes input text being invisible in Chrome 106/107
    height: 0;
    will-change: height;
    transition: height 0.2s ease-in-out;
  }

  div.visible {
    overflow: hidden;
    transform: translateZ(0); // Fixes input text being invisible in Chrome 106/107
    height: 9rem; // Only works for input[type=text]
    will-change: height;
    transition: height 0.2s ease-in-out;
  }
`
