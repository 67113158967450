import React, {ReactNode} from "react"
import {css} from "@linaria/core"

import {Sticker} from "./sticker"

export const CoverRight = () => (
  <div className={styles}>
    <Sticker position="left" />
  </div>
)

const styles = css`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: black;
  background-image: url(/images/fireworks.jpg);
  background-size: cover;
  background-position: center;
`
