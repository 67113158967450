import React, {useCallback, useEffect, useRef, useState} from "react"
import {css} from "@linaria/core"

import {
  mainWidth,
  mainHeight,
  openEasing,
  openSpeed,
  sneakPeekEasing,
  sneakPeekSpeed,
  appearEasing,
  appearSpeed,
  shadowColorRGB,
  leftPeekAngle,
  rightPeekAngle,
  closeEasing,
  closeSpeed,
} from "./layout/settings"

import {fetchInvitation, query} from "./content/data"
import {InnerLeft} from "./layout/inner-left"
import {InnerMiddle} from "./layout/inner-middle"
import {InnerRight} from "./layout/inner-right"
import {CoverLeft} from "./layout/cover-left"
import {CoverRight} from "./layout/cover-right"

const initialOpenState = false

export const Invitation = () => {
  const invitation = query()
  const invitationDetails = fetchInvitation()

  const [open, setOpen] = useState(initialOpenState)
  const [transition, setTransition] = useState<"opening" | "closing" | undefined>()

  const ref = useRef<HTMLElement>(null)

  const handleCoverClick = useCallback(
    (event: React.MouseEvent) => {
      if (!open) {
        event.preventDefault()
        event.stopPropagation()
        setOpen(true)
        setTransition("opening")
      }
    },
    [open, setOpen, setTransition],
  )

  const handleOuterClick = useCallback(
    (event: MouseEvent) => {
      if (open && (event.target as HTMLElement)?.contains(ref.current)) {
        event.preventDefault()
        event.stopPropagation()
        setOpen(false)
        setTransition("closing")
      }
    },
    [open, setOpen, setTransition],
  )

  const handleCloseButtonClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      setOpen(false)
      setTransition("closing")
    },
    [setOpen, setTransition],
  )

  const handleTransitionEnd = useCallback(() => {
    setTransition(undefined)
  }, [setTransition])

  useEffect(() => {
    document.addEventListener("click", handleOuterClick)
    return () => document.removeEventListener("click", handleOuterClick)
  }, [handleOuterClick])

  return (
    <main
      className={[styles, open ? "open" : "closed", transition].join(" ")}
      onClick={handleCoverClick}
      ref={ref}
    >
      <section className="card">
        <section className="cover">
          <section className="left" onTransitionEnd={handleTransitionEnd}>
            <CoverLeft />
          </section>
          <section className="right">
            <CoverRight />
          </section>
        </section>

        <section className="inner">
          <section className="left">
            <InnerLeft />
            <nav className="close">
              <button className="close" onClick={handleCloseButtonClick}>
                Uitnodiging sluiten
              </button>
            </nav>
          </section>
          <section className="middle">
            <InnerMiddle invitationDetails={invitationDetails} {...invitation} />
          </section>
          <section className="right">
            <InnerRight {...invitation} />
          </section>
        </section>
      </section>
    </main>
  )
}

const styles = css`
  display: flex;
  flex-direction: column;
  width: ${mainWidth + 3}rem;
  height: ${mainHeight + 3}rem;
  max-height: 95vh;
  max-width: 95vw;
  align-items: center;
  justify-content: center;

  @media (max-width: 70rem) {
    max-height: 100vh;
    max-height: 100svh;
  }

  > nav {
    z-index: 0;
    position: relative;
    margin: 2rem 0 0;
    line-height: 2rem;
    font-size: 1.4rem;
    font-weight: bold;
    width: 100%;
    text-align: center;
    will-change: opacity;
    animation: 1s ease-in-out 1s both fade-in;

    @media (max-width: 70rem) {
      display: none;
    }

    > img {
      height: 1.4rem;
      margin-left: 0.6rem;
    }
  }

  > section.card {
    z-index: 1;
    position: relative;
    width: ${mainWidth}rem;
    height: ${mainHeight}rem;
    max-height: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;

    opacity: 1;
    transform: scale(0.98);
    transition: transform ${sneakPeekSpeed} ${sneakPeekEasing};
    will-change: transform, opacity;
    animation: ${appearSpeed} 0.3s ${appearEasing} backwards scale-appear;

    @media (max-width: 70rem) {
      transform: scale(0.9);
      transition: transform ${openSpeed} ${openEasing};
    }

    // Drop shadow.
    &:before {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      background: rgba(${shadowColorRGB}, 0.5);
      filter: blur(2rem);
      transform-origin: bottom center;
      transform: translateY(1.2rem) scaleX(0.94) scaleY(0.97);
      transition: transform ${sneakPeekSpeed} ${sneakPeekEasing};
    }

    > section.cover {
      z-index: 2;
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      user-select: none;

      perspective: 120rem;
      perspective-origin: center;
      transform-style: preserve-3d;

      > section.left,
      > section.right {
        border-radius: 0.1rem;
        overflow: hidden;
        backface-visibility: hidden;
        will-change: transform;
        transition: transform ${sneakPeekSpeed} ${sneakPeekEasing};
        display: flex;
        height: 100%;
        width: ${mainWidth / 2}rem;

        &:before {
          // Cover shadow during opening.
          pointer-events: none;
          content: " ";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          will-change: opacity;
          transition: opacity ${sneakPeekSpeed} ${sneakPeekEasing};
          opacity: 0;
        }
      }

      > section.left {
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
        transform-origin: center left;
        transform: rotateY(-0.1deg);

        // border-right: 0.1rem solid hsla(35, 31%, 20%, 1);

        &:before {
          // Cover shadow during opening.
          background: linear-gradient(
            265deg,
            rgba(${shadowColorRGB}, 0.8),
            rgba(${shadowColorRGB}, 1)
          );
        }
      }

      > section.right {
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
        transform-origin: center right;
        transform: rotateY(0.1deg);

        &:before {
          // Cover shadow during opening.
          background: linear-gradient(
            95deg,
            rgba(${shadowColorRGB}, 0.8),
            rgba(${shadowColorRGB}, 1)
          );
        }

        &:after {
          // Drop shadow from left cover during opening.
          z-index: 2;
          pointer-events: none;
          content: " ";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          will-change: opacity;
          transition: opacity ${sneakPeekSpeed} ${sneakPeekEasing};
          opacity: 0;

          background: linear-gradient(
            89deg,
            rgba(${shadowColorRGB}, 0.9) 0%,
            rgba(${shadowColorRGB}, 0) 20%
          );
        }
      }
    }

    > section.inner {
      z-index: 1;
      position: absolute;
      display: flex;
      flex-direction: row;
      left: -${mainWidth / 2}rem;
      width: ${mainWidth * 2}rem;
      height: 100%;
      // Keep slightly smaller to make it fit behind cover.
      transform: scale(0.998);

      perspective: 120rem;
      perspective-origin: center;
      transform-style: preserve-3d;

      @media (max-width: 70rem) {
        left: 0;
        flex-direction: column;
        overflow: auto; // Allow scrolling on mobile.
      }

      > section.left {
        flex-direction: column;

        > nav.close {
          display: none;

          @media (max-width: 70rem) {
            display: block;
            order: 4;
            width: 100%;
            max-width: 95vw;
            background: #242424;
            text-align: center;
            font-weight: 200;
            font-size: 1.5rem;
          }

          > p {
            margin: 1.5rem 0 0;
            font-weight: bold;

            > img {
              height: 1.4rem;
              margin: 0 0 -0.1rem 0.3rem;
              vertical-align: baseline;
            }
          }

          > button {
            @media (max-width: 70rem) {
              display: block;
              width: 100%;
              padding-top: 0.5rem;
              border-width: 0;
              border-radius: 0;
              font-weight: 200;
              font-size: 1.4rem;
              background: transparent;
              color: white;
            }
          }
        }
      }

      > section.left,
      > section.right {
        width: ${mainWidth / 2}rem;
        backface-visibility: hidden;
        will-change: transform;
        transition: transform ${sneakPeekSpeed} ${sneakPeekEasing};
      }

      > section.left {
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
        transform-origin: center right;
        transform: rotateY(180deg);

        @media (max-width: 70rem) {
          transform: rotateY(0deg);
          order: 3;
        }

        &:before {
          // Inner during opening.
          background: linear-gradient(
            105deg,
            rgba(${shadowColorRGB}, 0.8),
            rgba(${shadowColorRGB}, 1)
          );
        }
      }

      > section.middle {
        width: ${mainWidth}rem;
        position: relative;

        @media (max-width: 70rem) {
          max-width: 95vw;
          order: 1;
        }

        &:before {
          // Inner during opening.
          background: linear-gradient(
            170deg,
            rgba(${shadowColorRGB}, 0.8),
            rgba(${shadowColorRGB}, 0.9)
          );
        }
      }

      > section.right {
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
        transform-origin: center left;
        transform: rotateY(-180deg);

        @media (max-width: 70rem) {
          transform: rotateY(0deg);
          order: 2;
        }

        &:before {
          // Inner during opening.
          background: linear-gradient(
            245deg,
            rgba(${shadowColorRGB}, 0.8),
            rgba(${shadowColorRGB}, 1)
          );
        }
      }

      > section.left,
      > section.right,
      > section.middle {
        display: flex;
        height: 100%;
        overflow: hidden;

        @media (max-width: 70rem) {
          width: ${mainWidth}rem;
          max-width: 95vw;
          height: auto;
          overflow: initial;
        }

        &:before {
          // Inner shadow during opening.
          z-index: 1;
          pointer-events: none;
          content: " ";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          will-change: opacity;
          transition: opacity ${sneakPeekSpeed} ${sneakPeekEasing};
          opacity: 1;
        }
      }
    }
  }

  &:hover {
    > section.card {
      transform: scale(1);

      @media (max-width: 70rem) {
        transform: scale(0.9);
      }

      &:before {
        transform: translateY(1.4rem) scaleX(0.94) scaleY(0.97);
      }

      > section.cover {
        > section.left {
          transform: rotateY(-${leftPeekAngle}deg);

          &:before {
            // Shadow.
            opacity: 0;
          }
        }

        > section.right {
          transform: rotateY(${rightPeekAngle}deg);

          &:before {
            // Shadow.
            opacity: 0.1;
          }

          &:after {
            // Shadow.
            opacity: 0.5;
          }
        }
      }

      > section.inner {
        > section.left,
        > section.right {
          &:before {
            // Shadow.
            opacity: 0.8;
          }
        }

        > section.left {
          transform: rotateY(${180 - leftPeekAngle}deg);

          @media (max-width: 70rem) {
            transform: rotateY(0deg);
          }
        }

        > section.right {
          transform: rotateY(${-180 + rightPeekAngle}deg);

          @media (max-width: 70rem) {
            transform: rotateY(0deg);
          }
        }
      }
    }
  }

  &.closed {
    cursor: pointer;

    > section.card {
      > section.inner {
        pointer-events: none;
      }
    }
  }

  &.open {
    cursor: initial;

    > section.card {
      transform: scale(1);

      &:before {
        transform: translateY(1.4rem) scaleX(0.94) scaleY(0.97);
      }

      > section.cover {
        pointer-events: none;

        > section.left,
        > section.right {
          &:before {
            // Shadow.
            opacity: 1;
          }
        }

        > section.left {
          transform: rotateY(-180deg);
        }

        > section.right {
          transform: rotateY(180deg);

          &:after {
            // Shadow.
            opacity: 0;
          }
        }
      }

      > section.inner {
        transform: scale(1);

        > section.left {
          &:before {
            // Shadow.
            opacity: 0;
          }
        }

        > section.right,
        > section.middle {
          &:before {
            // Shadow.
            opacity: 0;
          }
        }

        > section.left {
          transform: rotateY(0.1deg);
        }

        > section.right {
          transform: rotateY(-0.1deg);
        }
      }
    }
  }

  &.opening {
    pointer-events: none;

    > section.card {
      > section.cover {
        > section.left,
        > section.right {
          &:before {
            // Shadow.
            transition: opacity ${openSpeed} ${openEasing};
          }
        }

        > section.left {
          transition: transform ${openSpeed} ${openEasing};
        }

        > section.right {
          transition: transform ${openSpeed} ${openEasing};

          &:after {
            // Shadow.
            transition: opacity ${openSpeed} cubic-bezier(0.25, 0.5, 0.5, 1);
          }
        }
      }

      > section.inner {
        transform: scale(1);

        > section.left,
        > section.right,
        > section.middle {
          &:before {
            transition: opacity ${openSpeed} ${openEasing};
          }
        }

        > section.left {
          transition: transform ${openSpeed} ${openEasing};
        }

        > section.right {
          transition: transform ${openSpeed} ${openEasing};
        }
      }
    }
  }

  &.closing {
    pointer-events: none;

    > section.card {
      transform: scale(1);

      > section.cover {
        > section.left,
        > section.right {
          &:before {
            // Shadow.
            transition: opacity ${closeSpeed} ${closeEasing};
          }
        }

        > section.left {
          transition: transform ${closeSpeed} ${closeEasing};
        }

        > section.right {
          transition: transform ${closeSpeed} ${closeEasing};

          &:after {
            // Shadow.
            transition: opacity ${closeSpeed} cubic-bezier(0.5, 0, 0.75, 0.5);
          }
        }
      }

      > section.inner {
        transform: scale(1);

        > section.left,
        > section.right,
        > section.middle {
          &:before {
            transition: opacity ${closeSpeed} ${closeEasing};
          }
        }

        > section.left {
          transition: transform ${closeSpeed} ${closeEasing};
        }

        > section.right {
          transition: transform ${closeSpeed} ${closeEasing};
        }
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.6;
    }
  }

  @keyframes scale-appear {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      @media (max-width: 70rem) {
        transform: scale(0.9);
      }
      opacity: 1;
    }
  }

  @keyframes open-pulsate-left {
    0% {
      transform: rotateY(0.1deg);
    }
    100% {
      transform: rotateY(-${leftPeekAngle}deg);
    }
  }

  @keyframes open-pulsate-right {
    0% {
      transform: rotateY(0.1deg);
    }
    100% {
      transform: rotateY(${rightPeekAngle}deg);
    }
  }
`
