import React, {Suspense} from "react"
import {ErrorBoundary} from "react-error-boundary"

import {Modal} from "../components/content/modal"
import {Invitation} from "../components/invitation"
import {Layout} from "../components/layout/layout"

const IndexPage = () => {
  return (
    <Layout>
      <ErrorBoundary
        fallbackRender={({error}) => (
          <Modal open transparent title="Uitnodiging niet gevonden">
            <p>{error.message}</p>
            <p>
              Neem alstublieft contact op met Katinka Lemmen via
              <br />
              <a href="mailto:katinka@bouwensand.com">katinka@bouwensand.com</a> of{" "}
              <a href="tel:+31628825511">06-28825511</a>.
            </p>
          </Modal>
        )}
      >
        <Suspense>
          <Invitation />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  )
}

export const Head = () => (
  <>
    <title>FM Nieuwjaarsborrel 2023</title>
    <meta name="robots" content="none" />
    <meta name="icon" ref={() => "/favicon.ico"} />
    <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
  </>
)

export default IndexPage
