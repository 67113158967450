import React, {ReactNode} from "react"
import {css} from "@linaria/core"

import {Sticker} from "./sticker"

export const CoverLeft = () => (
  <div className={styles}>
    <Sticker position="right" />
    <h1>
      <span className="invitation">Uitnodiging</span>
      <span className="outline">FM</span>
      <span>Nieuw</span>
      <span>jaars</span>
      <span>borrel</span>
      <span className="outline">2023</span>
    </h1>
    <h3>
      <span className="bouwens" />
      <span className="name">André Kuipers</span>
    </h3>
  </div>
)

const styles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  background: rgb(36, 36, 36);
  background-image: url(/images/cover.jpg);
  background-size: cover;

  h1 {
    display: flex;
    margin: 4.5rem 5rem 0 5rem;
    flex-direction: column;
    font-size: 4.6rem;
    line-height: 4.4rem;

    @media (max-width: 35rem) {
      margin: 3.5rem 2.5rem 0 2.5rem;
      font-size: 4rem;
    }

    @media (max-width: 25rem) {
      margin: 3.5rem 2.5rem 0 2.5rem;
      font-size: 3rem;
      line-height: 3.2rem;
    }

    span {
      display: block;
      margin: 0;

      &.invitation {
        margin-bottom: 1rem;
        font-size: 2rem;

        @media (max-width: 35rem) {
          font-size: 1.8rem;
        }

        @media (max-width: 25rem) {
          font-size: 1.6rem;
        }
      }

      &.outline {
        color: transparent;
        -webkit-text-stroke: 0.07rem white;
        text-stroke: 0.07rem white;
      }
    }
  }

  h3 {
    display: flex;
    margin: 1rem 5rem 4.5rem 5rem;
    flex-direction: column;
    font-size: 2.1rem;

    @media (max-width: 35rem) {
      margin: 1rem 2.5rem 3.5rem 2.5rem;
      font-size: 1.8rem;
    }

    @media (max-width: 25rem) {
      font-size: 1.6rem;
    }

    span.bouwens {
      display: block;
      margin: 0.8rem 0;
      width: 100%;
      height: 1.5rem;
      background-image: url(/images/bouwens-colored.svg);
      background-size: contain;
      background-repeat: no-repeat;

      @media (max-width: 25rem) {
        height: 1.3rem;
      }
    }

    span.name {
      font-weight: normal;
    }
  }
`
