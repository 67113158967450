import {graphql, useStaticQuery} from "gatsby"

interface PendingResponse<T> {
  kind: "pending"
  promise: Promise<T>
}

interface ResolvedResponse<T> {
  kind: "resolved"
  value: T
}

interface RejectedResponse {
  kind: "rejected"
  error: Error
}

type InvitationResponse =
  | PendingResponse<InvitationDetails>
  | ResolvedResponse<InvitationDetails>
  | RejectedResponse

const responseCache = new Map<string, InvitationResponse>()

export const updateInvitation = async (details: InvitationPreferences) => {
  const response = await fetch(`/api/invitation/rsvp`, {
    method: "PUT",
    body: JSON.stringify(details),
  })

  if (!response.ok) {
    throw new Error(
      `RSVP voor de uitnodiging van '${details.email}' kon niet worden verstuurd.`,
    )
  }
}

export const sendConfirmationEmail = async (details: ConfirmationEmail) => {
  const response = await fetch(`/api/invitation/confirmation`, {
    method: "POST",
    body: JSON.stringify(details),
  })

  if (!response.ok) {
    throw new Error(`RSVP voor de uitnodiging kon niet worden verstuurd.
    Controleer je gegevens en probeer opnieuw.`)
  }
}

export const fetchInvitation = (): InvitationDetails => {
  if (typeof window === "undefined") {
    // At build time, return empty defaults.
    return {
      id: "",
      responded: false,
      name: "",
      email: "",
    }
  }

  const id = window.location.hash.slice(1)
  let response = responseCache.get(id)

  if (!response) {
    const promise = execFetchInvitation(id)
    response = {kind: "pending", promise}
    responseCache.set(id, response)
    promise
      .then(value => responseCache.set(id, {kind: "resolved", value}))
      .catch(error => responseCache.set(id, {kind: "rejected", error}))
  }

  switch (response.kind) {
    case "pending":
      throw response.promise

    case "rejected":
      throw response.error

    case "resolved":
      return response.value
  }
}

const execFetchInvitation = async (id: string): Promise<InvitationDetails> => {
  const response = await fetch(`/api/invitation/${id}`)
  if (response.ok) {
    return await response.json()
  } else {
    return {id, responded: false, name: "", email: ""}
    //throw new Error(`Uitnodiging met kenmerk '${id}' kon niet worden gevonden.`)
  }
}

export interface ConfirmationEmail {
  email: string
  name: string
  guestEmail?: string
  guestName?: string
  bringsGuest: boolean
  alternativeGuest: boolean
  id: string
}
export interface InvitationPreferences {
  dietaryWishes: string
  bringsGuest: boolean
  alternativeGuest: boolean
  guestName: string
  guestEmail: string
  email: string
}

export interface InvitationDetails {
  id: string
  responded: boolean
  name: string
  email: string
}

export interface Invitation {
  about: string
  content: string
  signUp: string
  program: Program[]
  footer: Footer[]
  response: Response[]
}

export interface Program {
  about: string
  date: string
  item: ProgramItem[]
}

export interface ProgramItem {
  time: string
  description: string
}

export interface Footer {
  title: string
  contentNode: {
    childMarkdownRemark: {
      html: string
    }
  }
}

export interface Response {
  about: string
  request: string
  submit: string
  thankYou: string
}

export const query = (): Invitation => {
  const {datoCmsInvitation} = useStaticQuery(graphql`
    query InvitationQuery {
      datoCmsInvitation {
        about
        content
        signUp
        program {
          about
          date
          item {
            time
            description
          }
        }
        footer {
          title
          contentNode {
            childMarkdownRemark {
              html
            }
          }
        }
        response {
          about
          request
          submit
          thankYou
        }
      }
    }
  `)

  return datoCmsInvitation as Invitation
}
