import React, {ReactNode} from "react"
import {css} from "@linaria/core"

import {ContentLeft} from "../content/content-left"
import {ContentMiddle} from "../content/content-middle"

type Props = Parameters<typeof ContentMiddle>[0]

export const InnerMiddle = (props: Props) => (
  <div className={styles}>
    <ContentLeft />
    <ContentMiddle {...props} />
  </div>
)

const styles = css`
  display: flex;
  flex-direction: row;
  margin-left: -50%;
  width: 150%;
  height: 100%;
  background: url(/images/paper.jpg);
  background-size: cover;
  background-position: 100% 50%;

  @media (max-width: 70rem) {
    margin-left: 0;
    width: 100%;
    height: auto;

    > div:first-child {
      display: none; // Hide left content (duplicate).
    }
  }

  @media (max-height: 50rem) and (min-width: 70rem) {
    margin-left: 0;
    width: 100%;
    height: max-content;
    min-height: 100%;

    > div:first-child {
      display: none; // Hide left content (duplicate).
    }
  }
`
