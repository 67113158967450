import React from "react"
import {css} from "@linaria/core"

import {Invitation, InvitationDetails} from "./data"
import {ResponseButton} from "./response-button"
import {mainWidth, textColorHex, redColorHex, footerHeight} from "../layout/settings"
import {ContactButton} from "./contact-button"
import {Footer} from "./footer"

interface Props extends Omit<Invitation, "program"> {
  invitationDetails: InvitationDetails
}

export const ContentMiddle = ({
  invitationDetails,
  about,
  content,
  signUp,
  footer,
  response,
}: Props) => (
  <div className={styles}>
    <article>
      <h2>{about}</h2>
      <h1>
        Beste {invitationDetails.name ? invitationDetails.name.split(" ")[0] : "bezoeker"}
        ,
      </h1>
      {splitParagraphs(content)}
      {invitationDetails.name && (
        <ResponseButton
          invitationDetails={invitationDetails}
          signUp={signUp}
          {...response[0]}
        />
      )}
      {!invitationDetails.name && <ContactButton />}
    </article>

    <Footer footers={footer.slice(0, 2)} />
  </div>
)

const splitParagraphs = (content: string) => {
  return content.split(/\n+/).map((paragraph, idx) => <p key={idx}>{paragraph}</p>)
}

const styles = css`
  width: 100%;
  min-width: ${mainWidth / 2}rem;
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${textColorHex};

  @media (max-width: 70rem) {
    height: auto;
  }

  @media (max-height: 50rem) and (min-width: 70rem) {
    height: auto;
    overflow: auto;
  }

  > article {
    height: 80%;
    padding: 5rem 2rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    flex-grow: 1;

    @media (max-width: 70rem) {
      padding-bottom: 5rem;
    }

    @media (max-height: 50rem) and (min-width: 70rem) {
      padding: 4rem 2rem 2rem;
    }

    h2 {
      margin: 0;
      text-align: center;
      width: 100%;
      color: ${redColorHex};
      font-size: 2rem;
    }

    h1 {
      margin: 2rem 0;
      text-align: center;
      width: 100%;
      color: ${textColorHex};
      font-size: 2.9rem;
      line-height: 3.6rem;
      text-transform: none;

      @media (max-width: 70rem) {
        font-size: 2.5rem;
        line-height: 2.7rem;
      }

      @media (max-height: 50rem) {
        font-size: 2.5rem;
        line-height: 2.7rem;
      }

      span.line {
        display: inline-block;
      }
    }

    p {
      margin: 0 2.4rem 2rem;
      text-align: center;
      font-size: 1.5rem;
      line-height: 2.2rem;

      @media (max-width: 70rem) {
        margin: 0 0.5rem 2rem;
      }
    }

    button {
      margin: 0 auto;
      min-width: 60%;
    }
  }

  > nav.footer {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    padding: 2rem 5rem;
    flex-direction: row;
    box-sizing: border-box;
    height: 20%;
    min-height: ${footerHeight}rem;
    background: #242424;
    mix-blend-mode: multiply;
    color: white;
    font-size: 1.4rem;
    align-items: center;

    @media (max-width: 70rem) {
      padding: 3rem 2.5rem;
      flex-direction: column;
      height: auto;
    }

    @media (max-height: 50rem) and (min-width: 70rem) {
      padding-top: 3rem;
      align-items: start;
      min-height: 12rem;
      height: 15%;
      font-size: 1.2rem;
    }

    @media (max-height: 42rem) and (min-width: 70rem) {
      background: transparent;
      color: ${textColorHex};
      height: auto;
      min-height: min-content;
      padding-top: 0;
    }

    a {
      color: white;
      text-decoration: underline;

      @media (max-height: 42rem) and (min-width: 70rem) {
        color: ${textColorHex};
      }
    }

    > div {
      @media (max-width: 70rem) {
        width: 100%;
      }

      @media (max-height: 50rem) and (min-width: 70rem) {
        min-width: 35%;
      }

      &:first-child {
        margin-right: 4rem;

        @media (max-width: 70rem) {
          margin-right: 0;
          margin-bottom: 2rem;
        }
      }
    }

    h3 {
      margin: 0;
      font-size: 1.5rem;

      @media (max-height: 50rem) and (min-width: 70rem) {
        font-size: 1.3rem;
      }

      @media (max-height: 42rem) and (min-width: 70rem) {
        color: ${textColorHex};
      }
    }

    p {
      margin: 0.6rem 0 0;

      @media (max-height: 50rem) and (min-width: 70rem) {
        line-height: 1.8rem;
      }
    }
  }
`
