import React from "react"
import {css} from "@linaria/core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faScrubber} from "@fortawesome/pro-solid-svg-icons"

import {redColorHex, redLightColor, textColorHex} from "../layout/settings"

interface Props {
  checked: boolean
}

export const Checkbox = ({checked}: Props) => (
  <div className={[styles, checked ? "checked" : "unchecked"].join(" ")}>
    <FontAwesomeIcon icon={faScrubber} />
  </div>
)

const styles = css`
  cursor: pointer;
  display: flex;
  width: 1.3rem;
  height: 1.3rem;
  margin: 0rem 1rem 0 0;
  background-color: white;
  color: ${redColorHex};
  border: 0.4rem solid ${redLightColor};
  border-radius: 50%;
  box-shadow: inset 0 0 0 0.1rem ${redColorHex};
  will-change: border-color, background-color;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  align-items: center;
  justify-content: center;

  > svg {
    will-change: opacity, transform;
    opacity: 0;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  &:hover,
  label:hover > & {
    border-color: ${redColorHex};
  }

  &.checked {
    color: ${redColorHex};
    background-color: white;

    > svg {
      opacity: 1;
      transform: scale(0.9);
    }
  }
`
