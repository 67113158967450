import React, {ReactNode} from "react"
import {css} from "@linaria/core"

interface Props {
  position: "left" | "right"
}

export const Sticker = ({position}: Props) => (
  <div className={[styles, position].join(" ")} />
)

const styles = css`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url(/images/sticker.svg);
  background-origin: center;
  background-size: 17rem;
  background-repeat: no-repeat;

  &.right {
    background-position: calc(100% + 8.5rem) 60%;
  }

  &.left {
    background-position: -8.5rem 60%;
  }

  @media (max-width: 35rem) {
    background-size: 12rem;

    &.right {
      background-position: calc(100% + 6rem) 60%;
    }

    &.left {
      background-position: -6rem 60%;
    }
  }
`
